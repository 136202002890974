import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone, faEnvelope, faLocationDot, faRocket, faArrowDown, faArrowUp, faAt } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faVk, faYandex } from '@fortawesome/free-brands-svg-icons' 
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './router'
import store from './store'
import axios from 'axios'

library.add(
    faPhone,
    faEnvelope,
    faLocationDot,
    faRocket,
    faArrowDown,
    faArrowUp,
    faAt,
    faFacebook,
    faVk,
    faYandex
    )

axios.defaults.baseURL = "http://127.0.0.1:8000"

createApp(App).use(store).use(router, axios).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
