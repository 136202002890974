<template>
    <article class="postnews flex flex-col shadow my-4 w-full">
                <!-- Article Image -->
                <img src="@/assets/content/news4.jpg" class="hover:opacity-75 flex object-cover">
                
                <div class="bg-white flex flex-col justify-start p-6">
                    <div>
                        <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Инструменты</a>
                         
                    </div>
                    <h2 class="font-Montserrat text-3xl font-medium hover:text-gray-700 pb-4"> </h2>
                    <p href="#" class="pb-3 text-sm">
                        Январь 21, 2023
                    </p>
                    <div class="pb-6 overflow-hidden">
                        <p>Поступил в продажу набор аккумуляторного инструмента 21В – болгарка + шуруповерт + гайковерт. В наборе 2 аккумулятора Li-Ion 21В емкостью 4Ач, зарядное устройство. Набор в едином кейсе. Стоимость – 12 000руб</p>
                        
                    </div>
                    <!-- <span @click="readMore = !readMore" class="text-slate-800 hover:text-black uppercase cursor-pointer">
                        <span v-if="!readMore">Читать далее <font-awesome-icon icon="arrow-down" class="px-1" /></span>
                        <span v-if="readMore">Свернуть <font-awesome-icon icon="arrow-up" class="px-1" /></span>
                    </span> -->
                </div>
            </article>
</template>

<script>
export default {
    data: () => {
        return {
            readMore: false
        }
    },
    name: 'BlogPost_news4'
}
</script>