<template>
  <Header />
  <BlogTape />
  <Footer />
  
</template>

<script>
// @ is an alias to /src
import Header from '@/components/layout/Header'
import BlogTape from '@/components/layout/BlogTape'
import Footer from '@/components/layout/Footer'


export default {
  name: 'HomeView',
  components: {
    Header,
    BlogTape,
    Footer
  }
}
</script>
