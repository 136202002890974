<template>
    <article class="postnews flex flex-col shadow my-4 w-full">
                <!-- Article Image -->
                <img src="@/assets/content/news1.jpg" class="hover:opacity-75 flex object-cover">
                
                <div class="bg-white flex flex-col justify-start p-6">
                    <div>
                        <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Снегоуборочная техника</a>
                         
                    </div>
                    <h2 class="font-Montserrat text-3xl font-medium hover:text-gray-700 pb-4"> </h2>
                    <p href="#" class="pb-3 text-sm">
                        Январь 13, 2023
                    </p>
                    <div class="pb-6 overflow-hidden">
                        <p>На сегодняшний день в нашем магазине представлены 4 модели снегоуборочных машин.</p>
                        <p>Мощность – 7-8 лошадиных сил. Стоимость – от 45 тысяч рублей.</p>
                        
                    </div>
                    <!-- <span @click="readMore = !readMore" class="text-slate-800 hover:text-black uppercase cursor-pointer">
                        <span v-if="!readMore">Читать далее <font-awesome-icon icon="arrow-down" class="px-1" /></span>
                        <span v-if="readMore">Свернуть <font-awesome-icon icon="arrow-up" class="px-1" /></span>
                    </span> -->
                </div>
            </article>
</template>

<script>
export default {
    data: () => {
        return {
            readMore: false
        }
    },
    name: 'BlogPost_news1'
}
</script>