<template>
    <article class="postnews flex flex-col shadow my-4">
                <!-- Article Image -->
                <img src="@/assets/content/redverg-saw1.jpeg" class="object-cover hover:opacity-75 flex">
                
                <div class="bg-white flex flex-col justify-start p-6">
                    <div>
                        <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Садовая техника</a>
                         | 
                        <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Бензопилы</a>
                    </div>
                    <h2 class="font-Oswald text-3xl font-medium hover:text-gray-700 pb-4">REDVERG RD-GC50-16 6615722</h2>
                    <p href="#" class="pb-3 text-sm">
                        Март 25, 2022
                    </p>
                    <div class="pb-6 overflow-hidden" :class="readMore ? 'max-h-fit gradient-mask-b-100' : 'max-h-40 gradient-mask-b-0'">
                        <p>Бензопила REDVERG RD-GC50-16 6615722 разработана для использования в фермерских и жилищно-коммунальных хозяйствах.</p>
                        <p>Оснащена мощным двигателем и металлическим упором, что обеспечивает точность реза и идеальную поверхность распиливания.</p>
                        <p>В конструкции пилы предусмотрен расширенный защитный экран на задней ручке, что обеспечивает защиту руки от царапин ветками и травм в случае разрыва цепи.</p>
                        <h3 class="font-bold text-lg">Преимущества REDVERG RD-GC50-16 6615722</h3>
                        <ul class="list-disc list-inside">
                            <li>Карбюратор подогревается потокам горячего воздуха идущего от двигателя, обеспечивая поступление  тёплой бензосмеси в цилиндр, лучший запуск, лучшее сгорание топлива особенно в холодное время года</li>
                            <li>Регулировка масляного насоса подачи цепного масла позволит эффективно контролировать его расход в процессе эксплуатации</li>
                            <li>Тормоз цепи</li>
                            <li>Легкий запуск REDVERG RD-GC50-16 6615722</li>
                            <li>4-х зубый металлический упор</li>
                            <li>Боковое расположение винта натяжения цепи</li>
                            <li>При выполнении натяжения цепи исключен контакт с острой цепью пилы</li>
                        </ul>
                    </div>
                    <span @click="readMore = !readMore" class="text-slate-800 hover:text-black uppercase cursor-pointer">
                        <span v-if="!readMore">Читать далее <font-awesome-icon icon="arrow-down" class="px-1" /></span>
                        <span v-if="readMore">Свернуть <font-awesome-icon icon="arrow-up" class="px-1" /></span>
                    </span>
                </div>
            </article>
</template>

<script>
export default {
    data: () => {
        return {
            readMore: false
        }
    },
    name: 'BlogPost2'
}
</script>