<template>
    <article class="postnews flex flex-col shadow my-4">
                <!-- Article Image -->
                <img src="@/assets/content/gardena_siscour.jpeg" class="hover:opacity-75 flex object-cover">
                
                <div class="bg-white flex flex-col justify-start p-6">
                    <div>
                        <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Садовая техника</a>
                         | 
                        <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Триммеры</a>
                    </div>
                    <h2 class="font-Oswald text-3xl font-medium hover:text-gray-700 pb-4">GARDENA ClassicCut Li 09855-20.000.00</h2>
                    <p href="#" class="pb-3 text-sm">
                        Апрель 25, 2022
                    </p>
                    <div class="pb-6 overflow-hidden transition ease-in-out duration-300" :class="readMore ? 'max-h-fit gradient-mask-b-100' : 'max-h-40 gradient-mask-b-0'">
                        Аккумуляторные ножницы для травы GARDENA ClassicCut Li 09855-20.000.00 благодаря эргономичной рукоятке ножницы удобно лежат в руке.

                        Замена лезвия без инструмента упрощает обслуживание инструмента.
                        
                        Благодаря телескопической рукоятке можно стричь кромки в комфортном положении.
                        
                        Аккумулятор можно быстро перезарядить в любой время без эффекта памяти.
                        Преимущества Gardena ClassicCut Li 09855-20.000.00
                        <p> Аккумуляторный двигатель - для независимости от розетки;
                        Прецизионная заточка лезвий - для износостойкости GARDENA ClassicCut Li 09855-20.000.00;
                        Замена оснастки без усилий - для простого использования;
                        Телескопическая ручка - для работы стоя;
                        Индикаторы заряда аккумулятора - для своевременного выключения из сети;
                        Эргономичная рукоять - для надежного охвата;
                        Оптимальная геометрия лезвия - для быстрой стрижки;
                        Li-ion аккумулятор не требует дополнительного обслуживания.
                        </p>
                    </div>
                    <span @click="readMore = !readMore" class="text-slate-800 hover:text-black uppercase cursor-pointer">
                        <span v-if="!readMore">Читать далее <font-awesome-icon icon="arrow-down" class="px-1" /></span>
                        <span v-if="readMore">Свернуть <font-awesome-icon icon="arrow-up" class="px-1" /></span>
                    </span>
                </div>
            </article>
</template>

<script>
export default {
    data: () => {
        return {
            readMore: false
        }
    },
    name: 'BlogPost'
}
</script>