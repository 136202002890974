<template>
    <footer class="w-full bg-dreller-black text-white pb-12 mb-0 relative">
    
        <div class="w-full container mx-auto flex flex-col items-center">
            <div class="flex flex-col md:flex-row text-center md:text-left md:justify-between py-6 font-Intro uppercase text-dreller-yellow">
                <a href="#" class="px-3 py-2">&copy; dreller.ru 2022</a>
                <a href="#" class="px-3 py-2 text-white">+7(968)945-46-56</a>
                <a href="#" class="px-3 py-2">ТЦ "Баррикада"</a>
                <a href="#" class="px-3 py-2">г.Орехово-Зуево</a>
            </div>
            <a class="text-slate-600 pb-6 flex px-1 items-center" href="https://suphixware.com" target="blank">
             <font-awesome-icon icon="terminal" class="px-1" />
            suphixware.com
            </a>
        </div>
        <div class="absolute bg-dreller-curb2 top-0 inset-x-0 h-4 -translate-y-6"></div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>