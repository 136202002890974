<template>
    <!-- Top Bar Nav -->
<nav class="w-full py-4 bg-dreller-black shadow">
    <div class="w-full container max-w-full mx-auto flex flex-wrap items-center justify-between">
        <div class="w-full lg:w-2/3 flex flex-col md:flex-row justify-between px-3 mx-auto uppercase">

            <nav class="flex items-center justify-center">
                <ul class="flex items-center justify-between font-bold text-xs md:text-sm xl:text-base text-white no-underline">
                    <li><a class="text-dreller-yellow hover:text-gray-200 hover:underline px-2 py-2 whitespace-nowrap" href="#"><font-awesome-icon icon="phone" class="hidden sm:inline-block px-1" />+7(968)945-46-56</a></li>
                    <li><a class="hover:text-gray-200 hover:underline px-2 py-2" href="#"><font-awesome-icon icon="envelope" class="hidden sm:inline px-1" />dreller@inbox.ru</a></li>
                </ul>
            </nav>

            <div class="hidden xs:flex items-center font-bold text-xs md:text-sm xl:text-base no-underline text-dreller-yellow mx-auto md:mr-0">
                <a href="#" class="py-2">
                    <font-awesome-icon icon="location-dot" class="px-1" />
                    ТЦ Баррикада г. Орехово-Зуево
                </a>
            </div>
        </div>
    </div>

</nav>

<!-- Text Header -->
<header class="w-full container mx-auto relative max-w-full py-8">
    <div class="flex flex-col xs:flex-row md:items-center justify-center w-full">
        <!-- <div class="flex pt-2 xs:pt-0 px-24 xs:px-0 xs:basis-24 items-center"><router-link to="/" ><img src="@/assets/electric-drill.svg" alt="logo" srcset="" /></router-link></div>
        <div class="flex flex-col items-center pb-12 pt-1 xs:pt-12 shrink">
            <router-link to="/" class="font-Oswald font-bold text-gray-800 hover:text-gray-700 text-5xl" >
                Дреллер
            </router-link>
            <p class="font-Oswald text-lg text-gray-600">
                Магазин хорошего инструмента
            </p>
        </div> -->
        <div class="flex pt-2 xs:pt-0 xs:px-0 items-center">
            <router-link to="/" ><img src="@/assets/design/dreller_logo.svg" alt="logo" srcset="" /></router-link> 
        </div>
    </div>
    <div class="absolute inset-x-0 top-1 bg-dreller-curb1 h-3"></div>
    <div class="absolute inset-x-0 bottom-1 bg-dreller-curb2 h-3"></div>
</header>

<!-- Main Nav -->
<nav id="mainnav" class="w-full py-4 bg-dreller-black text-white" x-data="{ open: false }">
    
    <div class="w-full flex-grow sm:flex sm:items-center sm:w-auto">
        <div class="mainnavlist">
            <router-link to="/about" href="#" class="  text-center">О нас</router-link>
            <router-link to="/rent" href="#" class=" text-center">Прокат</router-link>
            <router-link to="/battery" href="#" class=" text-center">Ремонт аккумуляторов</router-link>
            <router-link to="/chain" href="#" class=" text-center">Заточка цепей</router-link>
            <router-link to="/contact" href="#" class=" text-center">Контакты</router-link>
        </div>
    </div>
</nav>

<!-- Заголовок Новости -->
<!-- <section class="w-full flex-grow sm:flex sm:items-center sm:w-auto mt-2">
    <div class="font-Oswald w-full container mx-auto flex flex-col sm:flex-row items-center justify-center text-sm uppercase mt-0 px-6 py-2">
        <h2 class="p-4 pb-0 text-xl">Новости нашего магазина</h2>
    </div>

</section> -->
</template>

<style scoped>
    #mainnav .router-link-exact-active {
        @apply text-dreller-yellow; 
    }
</style>

<script>
export default {
    name: 'Header'
}
</script>